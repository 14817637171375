import React from 'react'
import { motion } from 'framer-motion'

function SvgContactUs(props) {
  return (
    <svg
      viewBox="0 0 2000 1000"
      fillRule="evenodd"
      clipRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
      {...props}
    >
      <defs>
        <linearGradient
          y1={493.13}
          id="ContactUs_svg__a"
          x1={0}
          y2={493.13}
          x2={1096}
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(109.427 -50.21) scale(1.62206)"
        >
          <stop stopColor="#35b775" offset={0} />
          <stop stopColor="#2196f3" offset={1} />
        </linearGradient>
        <linearGradient
          y1={565.88}
          id="ContactUs_svg__b"
          x1={0}
          y2={565.88}
          x2={1096}
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(109.427 -50.21) scale(1.62206)"
        >
          <stop stopColor="#35b775" offset={0} />
          <stop stopColor="#2196f3" offset={1} />
        </linearGradient>
        <linearGradient
          y1={341.3}
          id="ContactUs_svg__c"
          x1={0}
          y2={341.3}
          x2={1096}
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(109.427 -50.21) scale(1.62206)"
        >
          <stop stopColor="#35b775" offset={0} />
          <stop stopColor="#2196f3" offset={1} />
        </linearGradient>
        <linearGradient
          y1={414.05}
          id="ContactUs_svg__d"
          x1={2.08}
          y2={414.05}
          x2={1098.08}
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(109.427 -50.21) scale(1.62206)"
        >
          <stop stopColor="#35b775" offset={0} />
          <stop stopColor="#2196f3" offset={1} />
        </linearGradient>
      </defs>
      <g>
        <path
          d="M109.427 749.674c0-7.937 397.968-14.372 888.886-14.372 490.917 0 888.887 6.435 888.887 14.372s-397.97 14.371-888.887 14.371c-490.918 0-888.886-6.434-888.886-14.371z"
          opacity={0.22}
          fill="url(#ContactUs_svg__a)"
        />
        <path
          d="M109.427 867.678c0-7.937 397.968-14.371 888.886-14.371 490.917 0 888.887 6.434 888.887 14.371s-397.97 14.372-888.887 14.372c-490.918 0-888.886-6.435-888.886-14.372z"
          opacity={0.177}
          fill="url(#ContactUs_svg__b)"
        />
        <path
          d="M109.427 503.397c0-7.937 397.968-14.371 888.886-14.371 490.917 0 888.887 6.434 888.887 14.371s-397.97 14.371-888.887 14.371c-490.918 0-888.886-6.434-888.886-14.371z"
          opacity={0.218}
          fill="url(#ContactUs_svg__c)"
        />
        <path
          d="M112.801 621.401c0-7.937 397.968-14.371 888.889-14.371 490.92 0 888.88 6.434 888.88 14.371 0 7.938-397.96 14.372-888.88 14.372-490.921 0-888.889-6.434-888.889-14.372z"
          opacity={0.225}
          fill="url(#ContactUs_svg__d)"
        />
        <path
          stroke="#3f3d56"
          strokeWidth={4}
          d="M1186.97 745.018c4.24-7.85-.56-17.453-6.08-24.444-5.51-6.991-12.26-14.274-12.12-23.179.22-12.798 13.81-20.373 24.66-27.153a120.472 120.472 0 0022.14-17.843 32.787 32.787 0 006.8-9.116c2.25-4.996 2.19-10.705 2.04-16.22-.46-18.265-1.37-36.518-2.71-54.761"
          fill="none"
          strokeLinecap="butt"
          strokeLinejoin="miter"
        />
        <path
          d="M1206.73 706.543a19.963 19.963 0 01-21.95 10.386 19.977 19.977 0 01-15.75-18.496c-.14-2.765.3-5.53 1.3-8.11a19.989 19.989 0 0118.73-12.71 19.978 19.978 0 0118.58 12.92l-12.47 10.155 13.72-3.245c.01 3.162-.73 6.282-2.16 9.1z"
          fill="#2196f3"
        />
        <path
          d="M1196.87 667.776c-10.15.478-19.03-6.736-20.65-16.762-1.62-10.026 4.54-19.671 14.32-22.411l-.11 8.224 4.52-8.953c11.02-.516 20.37 7.999 20.89 19.018.51 11.019-8 20.369-19.02 20.884h.05zM1245.35 635.513a19.968 19.968 0 01-19.53-12.424 19.97 19.97 0 0128.36-24.883l-3.52 9.732 7.18-7.104a19.966 19.966 0 015.28 21.518 19.96 19.96 0 01-17.83 13.145l.06.016z"
          fill="#35b775"
        />
        <path
          d="M1245.35 635.513c-7 .332-13.66-3.034-17.55-8.867a19.961 19.961 0 01-1.44-19.616 14.754 14.754 0 014 4.023 69.87 69.87 0 003.96 6.602c3.12 3.649 8.44 4.866 13.25 4.622 4.82-.243 9.48-1.622 14.28-2.027.66 0 1.33-.097 2.01-.114a19.97 19.97 0 01-18.51 15.377zM1196.87 667.776a19.977 19.977 0 01-17.44-8.701 19.998 19.998 0 01-1.72-19.409 16.274 16.274 0 014.24 4.055 65.065 65.065 0 004.33 6.732c3.35 3.746 9.03 5.077 14.12 4.866 5.1-.211 9.74-1.314 14.6-1.622a19.998 19.998 0 01-18.13 14.079zM1206.73 706.543a19.963 19.963 0 01-21.95 10.386 19.977 19.977 0 01-15.75-18.496c-.14-2.765.3-5.53 1.3-8.11a19.824 19.824 0 014.38 3.909c1.9 2.303 3.15 4.866 5.35 7.088 4.08 4.007 10.68 5.661 16.53 5.807 3.39.01 6.78-.185 10.14-.584z"
          opacity={0.1}
        />
        {/* <motion.g
          animate={{ rotate: [10, 0, 10] }}
          transition={{ duration: 2, loop: Infinity }}
        > */}
        <g>
          <path
            stroke="#3f3d56"
            strokeWidth={4}
            d="M197.52 628.23c4.234-7.85-.567-17.453-6.082-24.444s-12.263-14.307-12.117-23.179c.211-12.798 13.804-20.373 24.655-27.153a120.512 120.512 0 0022.141-17.843 32.66 32.66 0 006.797-9.116c2.254-4.996 2.189-10.705 2.043-16.22a1172.07 1172.07 0 00-2.708-54.761"
            fill="none"
            strokeLinecap="butt"
            strokeLinejoin="miter"
          />
          <motion.g
            animate={{ rotate: [30, 0, 30] }}
            transition={{ duration: 2, loop: Infinity }}
          >
            <path
              d="M217.277 589.755a19.966 19.966 0 01-37.696-8.11c-.15-2.765.293-5.53 1.297-8.11a19.982 19.982 0 0118.726-12.71 19.982 19.982 0 0118.581 12.92L205.712 583.9l13.722-3.245a19.843 19.843 0 01-2.157 9.1z"
              fill="#2196f3"
            />
          </motion.g>
          <motion.g
            animate={{ rotate: [-30, 0, -30] }}
            transition={{ duration: 3, loop: Infinity }}
          >
            <path
              d="M207.415 550.988c-10.145.478-19.033-6.736-20.652-16.762-1.619-10.026 4.546-19.671 14.326-22.411l-.114 8.224 4.526-8.953c11.019-.516 20.369 7.999 20.884 19.018.515 11.019-8 20.369-19.019 20.884h.049z"
              fill="#35b775"
            />
          </motion.g>
          <motion.g
            animate={{ rotate: [20, 0, -20, 20] }}
            transition={{ duration: 4, loop: Infinity }}
          >
            <path
              d="M255.898 518.725a19.967 19.967 0 118.824-37.307l-3.52 9.732 7.186-7.104a19.967 19.967 0 01-12.555 34.663l.065.016z"
              fill="#2196f3"
            />
          </motion.g>
          <path
            d="M255.898 518.725a19.967 19.967 0 01-18.994-28.483 14.762 14.762 0 014.006 4.023 67.8 67.8 0 003.958 6.602c3.115 3.649 8.435 4.866 13.252 4.622 4.818-.243 9.473-1.622 14.275-2.027.665 0 1.33-.097 2.011-.114a19.966 19.966 0 01-18.508 15.377zM207.415 550.988a19.966 19.966 0 01-19.156-28.11 16.215 16.215 0 014.233 4.055 66.956 66.956 0 004.331 6.732c3.358 3.746 9.035 5.077 14.128 4.866 5.093-.211 9.732-1.314 14.599-1.622a20 20 0 01-18.135 14.079zM217.277 589.755a19.966 19.966 0 01-37.696-8.11c-.15-2.765.293-5.53 1.297-8.11a19.914 19.914 0 014.38 3.909c1.898 2.303 3.146 4.866 5.352 7.088 4.072 4.007 10.674 5.661 16.529 5.807 3.388.01 6.774-.185 10.138-.584z"
            opacity={0.1}
          />
        </g>
        {/* </motion.g> */}
        <path
          stroke="#3f3d56"
          strokeWidth={4}
          d="M856.416 494.622c-4.234-7.835-17.437 6.099-24.429 0-6.991-6.099 12.247-14.274 12.101-23.18-.195-12.814-13.787-20.373-24.655-27.153a120.028 120.028 0 01-22.125-17.842 32.105 32.105 0 01-6.813-9.1c-2.238-5.012-2.173-10.706-2.043-16.221a1202.933 1202.933 0 012.708-54.76"
          fill="none"
          strokeLinecap="butt"
          strokeLinejoin="miter"
        />
        <motion.g
          animate={{ rotate: [30, 0, 30] }}
          transition={{ duration: 2, loop: Infinity }}
        >
          <path
            d="M836.659 456.163a19.967 19.967 0 0037.694-10.31 19.969 19.969 0 00-38.651-5.765l12.474 10.154-13.723-3.244a19.862 19.862 0 002.206 9.165z"
            fill="#35b775"
          />
        </motion.g>
        <motion.g
          animate={{ rotate: [-30, 0, -30] }}
          transition={{ duration: 3, loop: Infinity }}
        >
          <path
            d="M791.313 419.218c5.06 8.811 15.929 12.418 25.253 8.379 9.323-4.039 14.127-14.435 11.16-24.153l-6.874 4.515 5.102-8.638c-5.481-9.569-17.681-12.884-27.25-7.403a19.703 19.703 0 00-5.275 4.396 19.952 19.952 0 00-2.116 22.904z"
            fill="#2196f3"
          />
        </motion.g>
        <motion.g
          animate={{ rotate: [20, 0, -20, 20] }}
          transition={{ duration: 4, loop: Infinity }}
        >
          <path
            d="M787.45 365.133a19.969 19.969 0 10-8.84-37.308l3.536 9.733-7.267-6.959a19.803 19.803 0 00-6.488 13.788 19.299 19.299 0 00.502 5.498 19.953 19.953 0 0018.557 15.248z"
            fill="#35b775"
          />
        </motion.g>
        <path
          d="M787.45 365.133a19.984 19.984 0 0018.994-28.484 14.838 14.838 0 00-4.023 4.023 67.79 67.79 0 01-3.957 6.602c-3.099 3.65-8.419 4.866-13.236 4.623-4.818-.244-9.473-1.622-14.275-2.028-.665 0-1.346-.097-2.011-.113a19.953 19.953 0 0018.508 15.377zM791.313 419.218a19.95 19.95 0 0034.003.982 15.964 15.964 0 00-5.689-1.366 66.81 66.81 0 01-8.005-.019c-4.974-.822-9.146-4.873-11.722-9.291-2.577-4.417-4.14-8.905-6.503-13.17a19.95 19.95 0 00-2.084 22.864zM836.659 456.163c4.866 9.38 16.197 13.345 25.849 9.045 9.653-4.3 14.284-15.375 10.566-25.266a19.17 19.17 0 00-4.379 3.909c-1.898 2.304-3.245 4.866-5.353 7.089-4.088 4.006-10.673 5.661-16.545 5.807a87.833 87.833 0 01-10.138-.584z"
          opacity={0.1}
        />
        <motion.g
          animate={{ x: [500, 0, -2000, 0], y: [800, 40, -200, 0] }}
          transition={{ duration: 7 }}
        >
          <g>
            <path
              d="M1412.05 336.131c0-89.548 72.59-162.141 162.14-162.141 89.55 0 162.14 72.593 162.14 162.141 0 89.547-72.59 162.14-162.14 162.14-89.55 0-162.14-72.593-162.14-162.14z"
              fill="#3f3d56"
              opacity="0.1"
            />
            <motion.g
              animate={{ rotate: [-10, 0, -10] }}
              transition={{ duration: 2, loop: Infinity }}
            >
              <path
                d="M1609.18 356.84c-2.48-1.455-5.53-1.424-8 .042l-12.5 7.45c-2.8 1.668-6.3 1.473-8.87-.532-4.43-3.465-11.58-9.295-17.79-15.51-6.22-6.215-12.05-13.359-15.51-17.796-2.01-2.567-2.2-6.063-.53-8.861l7.45-12.504a7.897 7.897 0 00.03-8.024l-18.35-31.339a7.912 7.912 0 00-8.73-3.685c-3.32.807-7.62 2.775-12.14 7.297-14.14 14.141-21.65 37.994 31.53 91.174 53.18 53.18 77.02 45.676 91.17 31.528 4.53-4.528 6.49-8.843 7.3-12.168.83-3.41-.64-6.918-3.66-8.69-7.56-4.418-23.85-13.958-31.4-18.382z"
                fill="#35b775"
              />
            </motion.g>
            <path
              d="M1574.19 476.714c-77.64 0-140.58-62.941-140.58-140.583 0-77.643 62.94-140.584 140.58-140.584 77.64 0 140.59 62.941 140.59 140.584 0 77.642-62.95 140.583-140.59 140.583z"
              opacity={0.1}
            />
          </g>
        </motion.g>
        <motion.g
          animate={{ x: [1000, 0, -500, 0], y: [200, -100, 500, 0] }}
          transition={{ duration: 5 }}
        >
          <g>
            <path
              d="M839.546 582.407c0-89.547 72.593-162.14 162.144-162.14 89.54 0 162.14 72.593 162.14 162.14 0 89.548-72.6 162.141-162.14 162.141-89.551 0-162.144-72.593-162.144-162.141z"
              fill="#3f3d56"
              opacity="0.1"
            />
            <motion.g
              animate={{ rotate: [-10, 0, -10] }}
              transition={{ duration: 2, loop: Infinity }}
            >
              <path
                d="M1093.9 533.421l-48.55 49.392 34.07 37.486a5.868 5.868 0 011.25 6.379 5.868 5.868 0 01-5.39 3.629 5.89 5.89 0 01-4.15-1.72l-33.89-36.091-9.55 8.338a36.294 36.294 0 01-25.95 10.916 37.087 37.087 0 01-26.412-11.224l-7.931-8.111-33.804 36.01a5.856 5.856 0 01-8.289 0 5.84 5.84 0 010-8.289l33.934-36.139-48.548-49.392v98.832c.026 7.953 6.467 14.394 14.42 14.42h155.93c7.95-.026 14.39-6.467 14.42-14.42v-99.383l-1.56-.633z"
                fill="#35b775"
              />
              <path
                d="M1001.69 600.023a24.549 24.549 0 0017.71-7.299l70.71-71.971a14.136 14.136 0 00-8.93-3.244H922.32a14.014 14.014 0 00-8.922 3.244l70.722 71.971a24.464 24.464 0 0017.57 7.299z"
                fill="#35b775"
              />
            </motion.g>
            <path
              d="M999.643 420.283c-89.605-.045-162.272 72.574-162.287 162.178-.014 89.605 72.628 162.248 162.233 162.233 89.601-.015 162.221-72.682 162.181-162.287-.05-89.52-72.61-162.079-162.127-162.124zm0 302.708c-77.642 0-140.583-62.942-140.583-140.584s62.941-140.583 140.583-140.583c77.647 0 140.587 62.941 140.587 140.583s-62.94 140.584-140.587 140.584z"
              opacity={0.1}
            />
          </g>
        </motion.g>
        <motion.g
          animate={{ y: [200, 80, 500, 0] }}
          transition={{ duration: 6 }}
        >
          <g>
            <path
              d="M454.746 461.321c0-89.548 72.593-162.141 162.141-162.141 89.548 0 162.14 72.593 162.14 162.141 0 89.548-72.592 162.14-162.14 162.14-89.548 0-162.141-72.592-162.141-162.14z"
              fill="#3f3d56"
              opacity="0.1"
            />
            <motion.g
              animate={{ rotate: [-10, 0, -10] }}
              transition={{ duration: 2, loop: Infinity }}
            >
              <path
                d="M616.887 383.738L552.248 532.87v.551l64.655-20.373 64.655 20.373v-.551l-64.671-149.132z"
                fill="#35b775"
              />
            </motion.g>
            <path
              d="M616.887 299.132c-89.595-.045-162.257 72.557-162.287 162.151-.03 89.594 72.584 162.245 162.178 162.26 89.595.015 162.233-72.612 162.233-162.206 0-89.552-72.572-162.161-162.124-162.205zm0 302.708c-77.653 0-140.599-62.958-140.584-140.611.015-77.653 62.985-140.586 140.638-140.557 77.652.03 140.574 63.012 140.529 140.665-.009 77.636-62.948 140.567-140.583 140.567v-.064z"
              opacity={0.1}
            />
          </g>
        </motion.g>
        <path
          stroke="#3f3d56"
          strokeWidth={4}
          d="M1466.1 621.921c4.23-7.835-.57-17.454-6.1-24.445-5.53-6.991-12.25-14.258-12.1-23.179.19-12.798 13.79-20.357 24.65-27.153a119.744 119.744 0 0022.13-17.843 32.221 32.221 0 006.81-9.1c2.24-5.012 2.17-10.705 2.04-16.22-.47-18.275-1.37-36.529-2.7-54.761"
          fill="none"
          strokeLinecap="butt"
          strokeLinejoin="miter"
        />
        <motion.g
          animate={{ rotate: [20, 0, -20, 20] }}
          transition={{ duration: 4, loop: Infinity }}
        >
          <path
            d="M1485.85 583.413c-4.31 8.396-13.92 12.621-23.02 10.129-9.11-2.492-15.23-11.021-14.67-20.445.56-9.423 7.65-17.167 16.99-18.562 9.33-1.396 18.37 3.939 21.66 12.787l-12.47 10.154 13.72-3.244c.01 3.193-.74 6.343-2.21 9.181z"
            fill="#35b775"
          />
        </motion.g>
        <motion.g
          animate={{ rotate: [-30, 0, -30] }}
          transition={{ duration: 3, loop: Infinity }}
        >
          <path
            d="M1475.99 544.63c-10.15.488-19.04-6.724-20.67-16.754-1.62-10.03 4.55-19.68 14.33-22.419l-.1 8.224 4.53-8.938c11.01-.504 20.36 8.011 20.87 19.027a19.933 19.933 0 01-5.17 14.379 19.976 19.976 0 01-13.84 6.497l.05-.016z"
            fill="#2196f3"
          />
        </motion.g>
        <motion.g
          animate={{ rotate: [20, 0, 40, 20] }}
          transition={{ duration: 2, loop: Infinity }}
        >
          <path
            d="M1524.46 512.383a19.972 19.972 0 01-19.52-12.434 19.98 19.98 0 015.34-22.519 19.964 19.964 0 0123.02-2.354l-3.54 9.732 7.27-6.975a19.847 19.847 0 016.49 13.788 19.2 19.2 0 01-.5 5.499 19.97 19.97 0 01-18.56 15.263z"
            fill="#35b775"
          />
        </motion.g>
        <path
          d="M1524.46 512.383c-7 .322-13.66-3.046-17.54-8.875a19.999 19.999 0 01-1.46-19.608 14.774 14.774 0 014.03 4.006 61.433 61.433 0 003.96 6.602c3.09 3.666 8.41 4.866 13.23 4.639 4.82-.227 9.47-1.622 14.28-2.027.66 0 1.34-.114 2.01-.13a19.97 19.97 0 01-18.51 15.393zM1475.99 544.63a19.958 19.958 0 01-17.47-8.701 19.929 19.929 0 01-1.68-19.442 16.494 16.494 0 014.21 4.055c1.63 2.19 2.52 4.72 4.33 6.732 3.38 3.763 9.04 5.077 14.15 4.963 5.11-.113 9.73-1.33 14.6-1.703a19.98 19.98 0 01-18.14 14.096zM1485.85 583.413c-4.94 9.243-16.18 13.099-25.75 8.834-9.57-4.264-14.23-15.2-10.66-25.055a19.654 19.654 0 014.38 3.91c1.91 2.319 3.16 4.866 5.35 7.104 4.09 4.007 10.67 5.645 16.55 5.807 3.38-.003 6.77-.204 10.13-.6z"
          opacity={0.1}
        />
        <path
          stroke="#3f3d56"
          strokeWidth={4}
          d="M1724 868.473c4.24-7.835-.56-17.453-6.09-24.444-5.54-6.991-12.25-14.258-12.1-23.18.19-12.798 13.78-20.356 24.65-27.153a120.07 120.07 0 0022.13-17.842 32.411 32.411 0 006.81-9.1c2.24-5.012 2.17-10.706 2.04-16.221-.46-18.275-1.37-36.528-2.71-54.76"
          fill="none"
          strokeLinecap="butt"
          strokeLinejoin="miter"
        />
        <motion.g
          animate={{ rotate: [30, 0, 30] }}
          transition={{ duration: 3, loop: Infinity }}
        >
          <path
            d="M1743.76 829.965a19.969 19.969 0 01-23.03 10.13c-9.1-2.493-15.22-11.021-14.66-20.445.56-9.424 7.65-17.168 16.98-18.563 9.34-1.395 18.38 3.939 21.67 12.788l-12.48 10.154 13.73-3.244c.01 3.193-.75 6.342-2.21 9.18z"
            fill="#2196f3"
          />
        </motion.g>
        <motion.g
          animate={{ rotate: [20, 0, 40, 20] }}
          transition={{ duration: 4, loop: Infinity }}
        >
          <path
            d="M1733.9 791.182c-10.15.488-19.05-6.723-20.67-16.753-1.62-10.03 4.54-19.68 14.33-22.42l-.1 8.224 4.52-8.937c11.02-.505 20.36 8.01 20.88 19.026a19.938 19.938 0 01-5.18 14.379 19.934 19.934 0 01-13.83 6.497l.05-.016z"
            fill="#35b775"
          />
        </motion.g>
        <motion.g
          animate={{ rotate: [-30, 0, -30] }}
          transition={{ duration: 2, loop: Infinity }}
        >
          <path
            d="M1782.37 758.936a19.965 19.965 0 01-19.52-12.435 19.96 19.96 0 015.33-22.519 19.978 19.978 0 0123.03-2.354l-3.54 9.733 7.27-6.975a19.886 19.886 0 016.49 13.787c.09 1.848-.08 3.7-.51 5.499a19.967 19.967 0 01-18.55 15.264z"
            fill="#2196f3"
          />
        </motion.g>
        <path
          d="M1782.37 758.936c-7 .321-13.66-3.046-17.55-8.876a19.972 19.972 0 01-1.45-19.608 14.603 14.603 0 014.02 4.007 64.993 64.993 0 003.96 6.602c3.1 3.665 8.42 4.866 13.24 4.639 4.82-.228 9.47-1.622 14.27-2.028.67 0 1.35-.114 2.01-.13a19.961 19.961 0 01-18.5 15.394z"
          opacity={0.1}
        />
        <motion.g
          animate={{ rotate: [20, 0, 40, 20] }}
          transition={{ duration: 4, loop: Infinity }}
        >
          <path
            d="M1777.08 697.447a19.971 19.971 0 01-18.99 13.228 19.965 19.965 0 01-18.73-13.592 19.971 19.971 0 016.69-22.155l7.62 7.01-3.64-9.392a19.85 19.85 0 0115.22-.682c1.74.622 3.39 1.491 4.88 2.581a19.968 19.968 0 016.95 23.002z"
            fill="#35b775"
          />
        </motion.g>
        <path
          d="M1759.8 710.655c-7 .322-13.66-3.046-17.55-8.876a20.022 20.022 0 01-1.45-19.608 14.777 14.777 0 014.03 4.007 63.13 63.13 0 003.95 6.602c3.1 3.666 8.42 4.866 13.24 4.639 4.82-.227 9.47-1.622 14.27-2.028.67 0 1.35-.113 2.02-.13a19.981 19.981 0 01-18.51 15.394zM1733.9 791.182a19.955 19.955 0 01-19.16-28.143 16.378 16.378 0 014.22 4.056c1.62 2.189 2.51 4.72 4.33 6.731 3.37 3.763 9.04 5.077 14.15 4.964 5.1-.114 9.73-1.331 14.59-1.704a19.958 19.958 0 01-18.13 14.096zM1743.76 829.965c-4.94 9.243-16.18 13.099-25.75 8.835-9.58-4.265-14.23-15.2-10.66-25.055a19.847 19.847 0 014.38 3.909c1.91 2.32 3.24 4.866 5.35 7.105 4.09 4.006 10.67 5.644 16.54 5.807 3.39-.004 6.78-.204 10.14-.601z"
          opacity={0.1}
        />
        <motion.g
          animate={{ rotate: [-30, 0, -30] }}
          transition={{ duration: 3, loop: Infinity }}
        >
          <path
            d="M1512.21 455.215a19.968 19.968 0 01-22.89-3.413 19.969 19.969 0 0115.78-34.262l.73 10.329 3.8-9.329a19.852 19.852 0 0111.54 9.953 19.332 19.332 0 011.78 5.227 19.978 19.978 0 01-10.74 21.495z"
            fill="#2196f3"
          />
        </motion.g>
        <path
          d="M1512.21 455.215a19.988 19.988 0 01-19.64-.966 19.984 19.984 0 01-9.3-17.319c1.88.325 3.68 1.011 5.3 2.022a63.768 63.768 0 006.31 4.419c4.32 2.087 9.67 1.018 13.97-1.15 4.31-2.168 8-5.338 12.22-7.662.6-.271 1.18-.652 1.78-.937a19.958 19.958 0 01-10.64 21.593z"
          opacity={0.1}
        />
        <motion.g
          animate={{ rotate: [30, 0, 30] }}
          transition={{ duration: 3, loop: Infinity }}
        >
          <path
            d="M1524.46 512.383a19.972 19.972 0 01-19.52-12.434 19.98 19.98 0 015.34-22.519 19.964 19.964 0 0123.02-2.354l-3.54 9.732 7.27-6.975a19.847 19.847 0 016.49 13.788 19.2 19.2 0 01-.5 5.499 19.97 19.97 0 01-18.56 15.263z"
            fill="#35b775"
          />
        </motion.g>
        <path
          d="M1524.46 512.383c-7 .322-13.66-3.046-17.54-8.875a19.999 19.999 0 01-1.46-19.608 14.774 14.774 0 014.03 4.006 61.433 61.433 0 003.96 6.602c3.09 3.666 8.41 4.866 13.23 4.639 4.82-.227 9.47-1.622 14.28-2.027.66 0 1.34-.114 2.01-.13a19.97 19.97 0 01-18.51 15.393z"
          opacity={0.1}
        />
        <motion.g
          animate={{ rotate: [30, 0, 30] }}
          transition={{ duration: 3, loop: Infinity }}
        >
          <path
            d="M1225.42 576.853a19.972 19.972 0 01-19.52-12.434 19.98 19.98 0 015.34-22.519 19.964 19.964 0 0123.02-2.354l-3.53 9.732 7.26-6.974a19.843 19.843 0 016.49 13.787c.1 1.848-.07 3.7-.5 5.499a19.968 19.968 0 01-18.56 15.263z"
            fill="#2196f3"
          />
        </motion.g>
        <path
          d="M1225.42 576.853c-7 .322-13.66-3.045-17.54-8.875a19.972 19.972 0 01-1.45-19.608 14.881 14.881 0 014.02 4.007 64.869 64.869 0 003.96 6.601c3.1 3.666 8.42 4.866 13.23 4.639 4.82-.227 9.48-1.622 14.28-2.027.66 0 1.34-.114 2.01-.13a19.97 19.97 0 01-18.51 15.393z"
          opacity={0.1}
        />
        <path
          d="M253.811 451.311a19.967 19.967 0 11-32.076-20.996l7.953 6.623-4.235-9.175a19.966 19.966 0 0128.321 23.603l.037-.055z"
          fill="#35b775"
        />
        <motion.g
          animate={{ rotate: [50, 0, 50] }}
          transition={{ duration: 4, loop: Infinity }}
        >
          <path
            d="M253.811 451.311a19.967 19.967 0 01-33.248 8.165 14.786 14.786 0 015.147-2.398 67.807 67.807 0 007.554-1.474c4.492-1.687 7.447-6.275 8.858-10.888 1.411-4.613 1.699-9.459 2.952-14.112.226-.625.361-1.284.578-1.93a19.968 19.968 0 018.159 22.637z"
            opacity={0.1}
          />
        </motion.g>
      </g>
    </svg>
  )
}

export default SvgContactUs
