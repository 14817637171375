import React from 'react'
import { graphql } from 'gatsby'
import SiteNavigation from '../components/header/siteNavigation'
import CommonFooter from '../components/footer/commonFooter'
import ContactUsSvg from '../illustrations/ContactUs'
import Seo from '../components/seo/seo'
import { useTranslation } from 'react-i18next'

const ContactPage = ({ data }) => {
  const { t } = useTranslation()

  return(
  <>
    <Seo
      title={t('contact.seoTitle')}
      description={t('contact.seoDescription')}
      seoImage={data.backgroundImage.childImageSharp.resize.src}
    />
    <SiteNavigation />
    <div className="relative py-24">
      <div
        style={{
          position: 'absolute',
          zIndex: 1,
          overflow: 'hidden',
          bottom: 0,
          top: 0,
          height: '100%',
          width: '100%',
          backgroundSize: 'cover',
        }}
      >
        <ContactUsSvg />
      </div>
      <div className="section relative z-10">
        <div className="md:flex md:space-x-24">
          <div>
            <h1 className="text-3xl md:text-5xl font-semibold">{t('contact.title')}</h1>
            <p className='py-12'>{t('contact.introText')}</p>
          </div>
          <div className="maingrad p-12">
            {/* Contact form */}
            <form
              name="contact"
              method="POST"
              netlify-honeypot="bot-field"
              data-netlify="true"
              // action={`/grazie`}
            >
              <input type="hidden" name="form-name" value="contact" />
              <input type="hidden" name="bot-field" />
              <div className="field">
                <label className="label">
                  {t('contact.formFullName')}
                </label>
                <input className="input" type="text" name="fullname"></input>
              </div>
              <div className="field">
                <label className="label">
                  {t('contact.formCompanyName')}
                </label>
                <input className="input" type="text" name="companyname"></input>
              </div>
              <div className="field">
                <label className="label">{<>{t('contact.formEmail')}</>}</label>
                <input className="input" type="email" name="email"></input>
              </div>
              <div className="field">
                <label className="label">
                  {t('contact.formSubject')}
                </label>
                <input className="input" type="text" name="subject"></input>
              </div>
              <div className="field">
                <label className="label">
                  {t('contact.formMessage')}
                </label>
                <div className="control">
                  <textarea className="textarea" name="message"></textarea>
                </div>
              </div>
              <div className="field">
                <div className="control">
                  <label className="checkbox">
                    <input type="checkbox" name="privacy" />{' '}
                    {t('contact.formPrivacy')}
                  </label>
                </div>
              </div>
              <button
                type="submit"
                className="button is-dark is-medium is-fullwidth"
              >
                {t('contact.formSend')}
              </button>
            </form>
            {/* End of contact form */}
          </div>
        </div>
      </div>
    </div>

    <CommonFooter />
  </>
)
}

export default ContactPage

export const contactQuery = graphql`
  query {
    backgroundImage: file(relativePath: { eq: "heros/contact/ContactUs.png" }) {
      childImageSharp {
        fluid(maxWidth: 4000) {
          ...GatsbyImageSharpFluid
        }
        resize(width: 900, quality: 90) {
          src
        }
      }
    }
  }
`
